<template>
    <van-popup
        class="select-user"
        :get-container="getContainer"
        :value="show"
        position="left"
        @input="$emit('close')"
    >
        <my-loading :loading="loading">
            <div class="content">
                <slot name="header">
                    <p class="select-user_title">{{ title }}</p>
                </slot>
                <van-field v-model="filterText" placeholder="输入关键字进行过滤"></van-field>
                <el-tree
                    ref="tree"
                    icon-class="van-icon van-icon-play"
                    accordion
                    show-checkbox
                    node-key="id"
                    :data="treeData"
                    :default-expanded-keys="defaultExpanded"
                    :filter-node-method="filterNode"
                    @check="clickCheck"
                >
                </el-tree>
            </div>
            <slot name="footer">
                <div class="btn-group">
                    <van-button native-type="button" plain round @click="$emit('close')">
                        取消
                    </van-button>
                    <van-button
                        color="var(--mainColor)"
                        native-type="button"
                        round
                        @click="confirm"
                    >
                        确定
                    </van-button>
                </div>
            </slot>
        </my-loading>
    </van-popup>
</template>

<script>
import { Tree } from "element-ui";
import "element-ui/lib/theme-chalk/tree.css";
import { queryWithPartList } from "@/api/common.js";
import MyLoading from "@/components/MyLoading";
export default {
    components: { [Tree.name]: Tree, MyLoading },
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        isRadio: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: "选择负责人",
        },
        disabledUser: {
            type: Array,
            default: () => [],
        },
        selectedUser: {
            type: Array,
            default: () => [],
        },
        isSelectedWorkRoom: {
            type: Boolean,
            default: false,
        },
        formData: null,
        getContainer: {
            // 层叠样式出错使用
            type: String,
            default: "",
        },
    },
    data() {
        return {
            loading: false,
            treeData: [],
            defaultExpanded: [], // 默认展开
            filterText: "",
        };
    },
    watch: {
        show: {
            async handler(val) {
                if (!val) return;

                if (!this.regionalData) {
                    this.loading = true;
                    const { value } = await queryWithPartList(this.formData);
                    this.regionalData = value;
                    this.$nextTick(() => (this.loading = false));
                }

                let selectedUser;
                if (this.isSelectedWorkRoom) {
                    // 设置默认展开
                    this.defaultExpanded = [this.regionalData.currentId];
                    // 设置选中成员
                    selectedUser = this.selectedUser;
                } else {
                    // 设置默认展开
                    this.defaultExpanded = ["_" + this.regionalData.currentId];
                    // 设置选中成员
                    selectedUser = this.selectedUser.map((item) => `_${item}`);
                }

                this.$nextTick(() => this.$refs.tree?.setCheckedKeys(selectedUser));
                // 获取组件数据，设置禁用
                this.treeData = this.setData(this.regionalData.allRegion);
            },
            deep: true,
        },
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },

    methods: {
        setData(list) {
            return list.map((ele) => {
                let item;
                if (!ele.isMember) {
                    let disabled = false;
                    if (this.isSelectedWorkRoom && this.isRadio) {
                        disabled = ele.children?.length;
                    } else if (!this.isSelectedWorkRoom && this.isRadio) {
                        disabled = true;
                    }
                    item = {
                        label: ele.name,
                        children: this.setData(ele.children),
                        id: ele.id,
                        disabled,
                    };
                } else {
                    item = {
                        label: ele.name,
                        id: `_${ele.id}`,
                        disabled: this.disabledUser.includes(ele.id),
                    };
                }
                return item;
            });
        },

        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },

        clickCheck(e, v) {
            if (this.isRadio && v.checkedKeys.includes(e.id))
                this.$refs.tree.setCheckedKeys([e.id]);
        },

        confirm() {
            const checkedNodes = this.$refs.tree.getCheckedNodes(true);
            if (this.formData) {
                this.$emit("confirm", checkedNodes);
                this.$emit("close");
            } else {
                let users = [];
                checkedNodes.forEach(({ id, label }) => {
                    if (id.includes("_")) {
                        users.push({ id: id.slice(1), name: label });
                    }
                });
                this.$emit("confirm", users);
                this.$emit("close");
            }
        },
    },
};
</script>

<style scoped lang="less">
@active-color: var(--mainColor);
@default-bg: #e9edff;
@disable-color: #999;
@disable-bg: var(--separatorColor);

.select-user {
    box-sizing: border-box;
    padding: 36px 0 0 12px;
    width: 90%;
    height: 100vh;
    background-color: var(--cardBackgroundColor);
    height: 100%;

    .my-loading {
        display: flex;
        flex-direction: column;
    }

    .select-user_title {
        font-size: 16px;
        font-weight: 600;
        margin: 15px;
    }

    /deep/ .content {
        flex: 1;
        overflow-y: scroll;
    }

    .btn-group {
        height: 60px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .van-button {
            width: 30vw;
            height: 36px;
        }
    }

    /deep/.el-tree {
        background-color: var(--cardBackgroundColor);
        color: var(--titleColor);

        .el-tree-node__content {
            height: 36px;

            &:hover {
                background-color: var(--cardBackgroundColor);
            }
        }

        .el-checkbox__inner {
            transform: scale(1.4);
        }

        // 复选框设置(默认)
        .el-checkbox__input .el-checkbox__inner {
            background-color: @default-bg;
            border-color: @default-bg;

            &::after {
                border-color: @active-color;
            }

            &::before {
                background: @active-color;
            }
        }

        // 复选框设置(禁用)
        .el-checkbox__input.is-disabled .el-checkbox__inner {
            background-color: @disable-bg;
            border: none;

            &::before {
                background: @disable-color;
            }
        }
    }
}
</style>
