<template>
    <div class="my-loading">
        <van-loading v-if="loading" type="spinner" color="#1989fa" />
        <slot v-else />
    </div>
</template>

<script>
export default {
    props: {
        'loading': {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped lang="less">
.my-loading {
    width: 100%;
    height: 100%;
    .van-loading {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
